import { createContext } from "react";

// Store the data for some fields within a specific context.
// For example all the fields directly on the form, or each individual row of a subform table.
const FieldDataContext = createContext({
  fields: undefined,
  formValues: undefined,
  updateFormValue: undefined,
});

export default FieldDataContext;
