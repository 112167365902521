import React from "react";
import { Form } from "react-bootstrap";

const SocInput = ({
  responseData,
  lltTermFieldData,
  socTermFieldData,
  socCodeFieldData,
  buildInputName,
  updateFormValues,
}) => {
  const socTermInputName = buildInputName("soc_term");
  const socCodeInputName = buildInputName("soc_code");

  const hiddenFields = (
    <>
      <Form.Control
        hidden={true}
        name={socCodeInputName}
        value={socCodeFieldData.value || ""}
        readOnly={true}
      ></Form.Control>
      <Form.Control
        hidden={true}
        name={socTermInputName}
        value={socTermFieldData.value || ""}
        readOnly={true}
      ></Form.Control>
    </>
  );

  // Don't show this unless we have search results or an llt has been selected
  if (!responseData && !lltTermFieldData.value) {
    return hiddenFields;
  }

  const foundLlt =
    responseData &&
    responseData.find((llt) => {
      return llt.llt_name == lltTermFieldData.value;
    });

  // If we cannot find our LLT and we dont have an soc, mark as blank
  if (!foundLlt && !socTermFieldData.value) {
    return hiddenFields;
  }

  const formOptions = () => {
    return foundLlt.possible_socs.map((option, index) => {
      return (
        <option value={option.soc_name} key={index}>
          {option.soc_name == foundLlt.primary_soc.soc_name && "[P]"}{" "}
          {option.soc_name}
        </option>
      );
    });
  };

  const onChangeHandler = (e) => {
    const foundSoc = foundLlt.possible_socs.find((soc) => {
      return soc.soc_name == e.target.value;
    });

    updateFormValues([
      ["soc_term", foundSoc.soc_name],
      ["soc_code", foundSoc.soc_code],
    ]);
  };

  // If we have response data and we have more than one possible soc, show as a select box. Otherwise show as plain text with the selected LLT
  // TODO: Make sure this works when we have multiple socs
  const formControl =
    responseData && foundLlt && foundLlt.possible_socs.length > 1 ? (
      <Form.Select
        type="text"
        value={socTermFieldData.value || ""}
        name={socTermInputName}
        onChange={onChangeHandler}
        className="px-2 py-1"
      >
        {formOptions()}
      </Form.Select>
    ) : (
      <Form.Control
        readOnly={true}
        plaintext={true}
        type="text"
        value={socTermFieldData.value || ""}
        name={socTermInputName}
        className="px-2 py-1"
      ></Form.Control>
    );

  return (
    <div className="mb-3 align-items-center row">
      <Form.Label className="fw-bold lh-sm form-label col-form-label col-sm-5">
        System Organ Class
      </Form.Label>
      <div className="col">{formControl}</div>
      <Form.Control
        hidden={true}
        name={socCodeInputName}
        value={socCodeFieldData.value || ""}
        readOnly={true}
      ></Form.Control>
    </div>
  );
};

export default SocInput;
