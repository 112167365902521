import React from "react";

const ChangeReason = ({ changeReasons, changeReasonData }) => {
  const changeReasonOptions = changeReasons.map((changeReason) => {
    return (
      <option key={changeReason} value={changeReason}>
        {changeReason}
      </option>
    );
  });

  const change_reason_errors = changeReasonData["selection_error"]
    ? changeReasonData["selection_error"].map((error, index) => {
        return (
          <div key={index} className="invalid-feedback d-block">
            {error}
          </div>
        );
      })
    : [];

  const change_reason_info_errors = changeReasonData["info_error"]
    ? changeReasonData["info_error"].map((error, index) => {
        return (
          <div key={index} className="invalid-feedback d-block">
            {error}
          </div>
        );
      })
    : [];

  return (
    <div className="card mb-3 text-bg-light col-6">
      <div className="card-body">
        <h3 className="card-title fs-5 fw-bold">Edit form data</h3>

        <label htmlFor="change_reason" className="form-label">
          Reason for edit
        </label>
        <div id="change-reason">
          <select
            id="change_reason"
            name="form[change_reason]"
            className={`form-select mb-2${
              change_reason_errors.length > 0 && " is-invalid"
            }`}
            defaultValue={changeReasonData.selection ?? ""}
          >
            <option value=""></option>
            {changeReasonOptions}
          </select>
        </div>

        {change_reason_errors}
        <label htmlFor="change_reason_info" className="form-label mt-2">
          Additional information
        </label>
        <div>
          <textarea
            type="text"
            id="change_reason_info"
            name="form[change_reason_info]"
            className="form-control"
            defaultValue={changeReasonData.info ?? ""}
          />
        </div>
        {change_reason_info_errors}
      </div>
    </div>
  );
};

export default ChangeReason;
