import React from "react";
import { useContext } from "react";
import { InputContext } from "../../../contexts";
import { Form, Row, Col } from "react-bootstrap";
import RevisionInfo from "../RevisionInfo";

const SelectInput = ({
  fieldData,
  onChangeHandler,
  errors,
  field,
  displayItem,
  readonly,
  labelSubtext,
  options,
  inputName,
  fieldID,
}) => {
  // Get our inputContext
  const { inputContext } = useContext(InputContext);

  // Determine whether this field should be displayed normally or inline
  const displayInline =
    inputContext == "inline" ||
    inputContext == "table" ||
    inputContext == "subform";

  const fieldName = inputName;

  // Build our form label
  const formLabel = () => {
    if (displayItem.hideLabel) {
      return null;
    }

    let labelProps = {
      column: true,
      sm: displayInline ? "auto" : 5,
      className: "fw-bold lh-sm",
    };

    let upperLabel = <div>{displayItem.label}</div>;

    let lowerLabel = labelSubtext ? (
      <div className="fs-10pt fw-normal fst-italic text-muted">
        {labelSubtext}
      </div>
    ) : null;

    return (
      <Form.Label {...labelProps}>
        {upperLabel}
        {lowerLabel}
      </Form.Label>
    );
  };

  const formOptions = () => {
    let selectOptions = [];
    if (options) {
      selectOptions = options.mapping.map((option, index) => {
        return (
          <option value={option.label} key={index}>
            {option.label}
          </option>
        );
      });
    }

    return [<option value="" key="blank"></option>, ...selectOptions];
  };

  // Build our form input
  const formControls = () => {
    let formControlClasses = ["ps-2", "pe-5", "py-1", "w-max-content"];

    let formControlProps = {
      value: fieldData.value || "",
      name: fieldName,
      onChange: (e) => {
        onChangeHandler(e);
      },
      isInvalid: errors.length > 0,
    };

    if (readonly) {
      formControlProps["onChange"] = (e) => {
        e.preventDefault();
      };

      formControlClasses.push("noClick");
    }

    if (!displayInline) {
      formControlProps["sm"] = 8;
    } else {
      formControlProps["sm"] = "auto";
    }

    formControlProps["className"] = formControlClasses.join(" ");

    return <Form.Select {...formControlProps}>{formOptions()}</Form.Select>;
  };

  // Build our error messages
  const errorFeedback = (
    <Form.Control.Feedback type="invalid">
      {errors.join(", ")}
    </Form.Control.Feedback>
  );

  if (displayInline) {
    return (
      <Col sm={"auto"}>
        <Form.Group
          controlId={fieldName}
          as={Row}
          className="mb-3"
          id={fieldID}
        >
          {formLabel()}
          <Col sm={"auto"}>
            {formControls()}
            <RevisionInfo fieldData={fieldData}></RevisionInfo>
            {errorFeedback}
          </Col>
        </Form.Group>
      </Col>
    );
  } else {
    return (
      <Form.Group controlId={fieldName} as={Row} className="mb-3" id={fieldID}>
        {formLabel()}
        <Col sm={7}>
          <Form.Control name={fieldName} defaultValue="" hidden></Form.Control>
          {formControls()}
          <RevisionInfo fieldData={fieldData}></RevisionInfo>
          {errorFeedback}
        </Col>
      </Form.Group>
    );
  }
};

export default SelectInput;
