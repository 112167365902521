import React from "react";

import DropDownLinks from "../components/form_navigation/DropdownLinks";

const FormNavigationPage = ({ button_text, links }) => {
  return (
    <div className="mt-4 col-12 col-xl-10 d-flex justify-content-center">
      <DropDownLinks button_text={button_text} links={links} />
    </div>
  );
};

export default FormNavigationPage;
