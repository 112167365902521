import React from "react";
import { Button } from "react-bootstrap";

const ClearSelection = ({ handler, fieldData }) => {
  return (
    <Button
      size="sm"
      variant="outline-secondary"
      onClick={handler}
      disabled={!fieldData.entered}
    >
      Clear
    </Button>
  );
};

export default ClearSelection;
