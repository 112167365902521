import React from "react";
import { useContext } from "react";
import { FieldDataContext } from "../contexts";
import { isDependencyMet } from "./dependencies/dependency_helper";
import { Alert } from "react-bootstrap";

// Display Items
import {
  ProspectField,
  FieldSet,
  ProspectTable,
  TableCell,
  RowLabel,
  InlineFields,
  RadioTable,
  Subform,
  Meddra,
} from "../components/data_entry/display_items";

export const buildDisplayItems = (displayItems) => {
  return displayItems.map((displayItem, index) => {
    return selectDisplayComponent(displayItem, index);
  });
};

export const selectDisplayComponent = (displayItem, index) => {
  switch (displayItem.type) {
    case "field": {
      if (
        [
          "heading",
          "paragraph",
          "grey_alert",
          "orange_alert",
          "red_alert",
          "bulletpoint",
        ].includes(displayItem.widgetType)
      ) {
        let dependencyMet = false;

        if (displayItem.dependency) {
          const { formValues, subformsValues, externalReferences } =
            useContext(FieldDataContext);
          dependencyMet = isDependencyMet(
            { raw_dependency: displayItem.dependency },
            formValues,
            externalReferences,
            null,
            subformsValues
          );
        } else {
          dependencyMet = true;
        }

        if (dependencyMet) {
          switch (displayItem.widgetType) {
            case "heading": {
              return (
                <p key={index} className="fw-bold fs-6">
                  {displayItem.label}
                </p>
              );
            }
            case "paragraph": {
              return <p key={index}>{displayItem.label}</p>;
            }
            case "grey_alert": {
              return (
                <Alert
                  key={index}
                  variant="secondary"
                  className="px-2 py-1 col-8"
                >
                  <i className="far fa-exclamation-triangle me-2"></i>
                  {displayItem.label}
                </Alert>
              );
            }
            case "orange_alert": {
              return (
                <Alert
                  key={index}
                  variant="warning"
                  className="px-2 py-1 col-8"
                >
                  <i className="far fa-exclamation-triangle me-2"></i>
                  {displayItem.label}
                </Alert>
              );
            }
            case "red_alert": {
              return (
                <Alert key={index} variant="danger" className="px-2 py-1 col-8">
                  <i className="far fa-exclamation-triangle me-2"></i>
                  {displayItem.label}
                </Alert>
              );
            }
            case "bulletpoint": {
              return (
                <ul key={index}>
                  <li>{displayItem.label}</li>
                </ul>
              );
            }
          }
        } else {
          return null;
        }
      } else {
        return <ProspectField displayItem={displayItem} key={index} />;
      }
    }
    case "fieldset": {
      return <FieldSet displayItem={displayItem} key={index}></FieldSet>;
    }
    case "inlineFields": {
      return <InlineFields displayItem={displayItem} key={index} />;
    }
    case "table": {
      return (
        <ProspectTable displayItem={displayItem} key={index}></ProspectTable>
      );
    }
    case "tableCell": {
      return <TableCell displayItem={displayItem} key={index}></TableCell>;
    }
    case "rowLabel": {
      return <RowLabel displayItem={displayItem} key={index}></RowLabel>;
    }
    case "radioTable": {
      return <RadioTable displayItem={displayItem} key={index} />;
    }
    case "subform": {
      return <Subform displayItem={displayItem} key={index} />;
      // if (displayItem.subformType == "horizontal") {
      //   return <HorizontalSubform displayItem={displayItem} key={index} />;
      // } else {
      //   return <VerticalSubform displayItem={displayItem} key={index} />;
      // }
    }
    case "meddra": {
      return <Meddra displayItem={displayItem} key={index} />;
    }
    default: {
      console.warn(
        `Unable to render layout item of type: "${displayItem.type}"`
      );
      return null;
    }
  }
};
