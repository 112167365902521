import React from "react";
import { useContext } from "react";

import { Row, Col } from "react-bootstrap";

import { buildDisplayItems } from "../../../services/display_item_helper";
import { anyChildIsRendered } from "../../../services/dependencies/dependency_helper";
import { FieldDataContext, InputContext, FormContext } from "../../../contexts";

const InlineFields = ({ displayItem }) => {
  // Get required values from our context
  const { formValues } = useContext(FieldDataContext);
  const { fields, readonly } = useContext(FormContext);

  if (!anyChildIsRendered(displayItem.children, formValues, fields, readonly)) {
    return null;
  }

  return (
    <>
      <Row className="align-items-center inline-fields-row">
        <Col md={4} className="fw-bold">
          <p>{displayItem.label}</p>
        </Col>
        <Col className="row d-flex align-items-center">
          <InputContext.Provider value={{ inputContext: "inline" }}>
            {buildDisplayItems(displayItem.children)}
          </InputContext.Provider>
        </Col>
      </Row>
    </>
  );
};

export default InlineFields;
