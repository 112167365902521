import React from "react";
import { useContext } from "react";
import { FormContext } from "../../contexts";

const RevisionInfo = ({ fieldData }) => {
  const { auditPage } = useContext(FormContext);

  if (!auditPage) {
    return null;
  }

  const PreviousValue = () => {
    if (fieldData.previous_form_deleted) {
      return <div>Previous Value: [Form Deleted]</div>;
    }

    if (
      fieldData.previous_value == fieldData.value ||
      !fieldData.previous_form_exists
    ) {
      return null;
    }

    if (
      Array.isArray(fieldData.previous_value) &&
      Array.isArray(fieldData.value)
    ) {
      if (fieldData.previous_value.length == 0 && fieldData.value.length == 0) {
        return null;
      }
    }

    let displayValue;
    if (Array.isArray(fieldData.previous_value)) {
      if (fieldData.previous_value.length > 0) {
        displayValue = fieldData.previous_value.join(", ");
      } else {
        displayValue = "[Left Blank]";
      }
    } else if (!fieldData.previous_value) {
      displayValue = "[Left Blank]";
    } else {
      displayValue = fieldData.previous_value.toString();
    }

    return <div>Previous Value: {displayValue}</div>;
  };

  const CurrentValue = () => {
    if (fieldData.current_form_deleted) {
      return <div>Current Value: [Form Deleted]</div>;
    }

    if (
      fieldData.current_value == fieldData.value ||
      !fieldData.current_form_exists
    ) {
      return null;
    }

    if (
      Array.isArray(fieldData.current_value) &&
      Array.isArray(fieldData.value)
    ) {
      if (fieldData.current_value.length == 0 && fieldData.value.length == 0) {
        return null;
      }
    }

    let displayValue;

    if (Array.isArray(fieldData.current_value)) {
      if (fieldData.current_value.length > 0) {
        displayValue = fieldData.current_value.join(", ");
      } else {
        displayValue = "[Left Blank]";
      }
    } else if (!fieldData.current_value) {
      displayValue = "[Left Blank]";
    } else {
      displayValue = fieldData.current_value.toString();
    }

    return <div>Current Value: {displayValue}</div>;
  };

  return (
    <div className="text-success">
      {PreviousValue()}
      {CurrentValue()}
    </div>
  );
};

export default RevisionInfo;
