import React from "react";
import { Form } from "react-bootstrap";

const ApiResponseInput = ({ responseData }) => {
  return (
    <Form.Control
      hidden={true}
      name="api_response"
      value={JSON.stringify(responseData)}
      readOnly={true}
    ></Form.Control>
  );
};

export default ApiResponseInput;
