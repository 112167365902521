import React from "react";
import { Form } from "react-bootstrap";

const LltInput = ({
  responseData,
  lltTermFieldData,
  buildInputName,
  updateFormValues,
  lltCodeFieldData,
  unableToCodeFieldData,
}) => {
  const lltTermInputName = buildInputName("llt_term");
  const lltCodeInputName = buildInputName("llt_code");
  const unableToCodeInputName = buildInputName("unable_to_code");

  const hiddenFields = (
    <>
      <Form.Control
        hidden={true}
        name={lltCodeInputName}
        value={lltCodeFieldData.value || ""}
        readOnly={true}
      ></Form.Control>
      <Form.Control
        hidden={true}
        name={lltTermInputName}
        value={lltTermFieldData.value || ""}
        readOnly={true}
      ></Form.Control>
      <Form.Control
        hidden={true}
        name={unableToCodeInputName}
        value={unableToCodeFieldData.value || "0"}
        readOnly={true}
      ></Form.Control>
    </>
  );

  // Don't show this unless we have search results or an llt has been selected
  if (!responseData && !lltTermFieldData.value) {
    return hiddenFields;
  }

  // Build the options from our response data
  const formOptions = () => {
    let selectOptions = responseData.map((option, index) => {
      return (
        <option value={option.llt_name} key={index}>
          {option.llt_name}
        </option>
      );
    });

    selectOptions.push(
      <option value="Unable to Code" key="Unable to Code">
        Unable to Code
      </option>
    );

    if (lltTermFieldData.value) {
      // If an llt is selected, just return the options (don't allow the user to re-select "blank")
      return selectOptions;
    } else {
      // Otherwise return this with a blank option
      return [<option value={null} key="blank"></option>, ...selectOptions];
    }
  };

  const onChangeHandler = (e) => {
    if (e.target.value == "Unable to Code") {
      updateFormValues([
        ["llt_term", "Unable to Code"],
        ["llt_code", null],
        ["pt_term", null],
        ["pt_code", null],
        ["soc_term", null],
        ["soc_code", null],
        ["unable_to_code", "1"],
      ]);
    } else {
      const foundLlt = responseData.find((llt) => {
        return llt.llt_name == e.target.value;
      });

      updateFormValues([
        ["llt_term", foundLlt.llt_name],
        ["llt_code", foundLlt.llt_code],
        ["pt_term", foundLlt.pt_name],
        ["pt_code", foundLlt.pt_code],
        ["soc_term", foundLlt.primary_soc.soc_name],
        ["soc_code", foundLlt.primary_soc.soc_code],
        ["unable_to_code", "0"],
      ]);
    }
  };

  // If we have response data, show as a select box. Otherwise show as plain text with the selected LLT
  const formControl = responseData ? (
    <Form.Select
      type="text"
      value={lltTermFieldData.value || ""}
      name={lltTermInputName}
      onChange={onChangeHandler}
      className="px-2 py-1"
    >
      {formOptions()}
    </Form.Select>
  ) : (
    <Form.Control
      readOnly={true}
      plaintext={true}
      type="text"
      value={lltTermFieldData.value || ""}
      name={lltTermInputName}
      className="px-2 py-1"
    ></Form.Control>
  );

  return (
    <div className="mb-3 align-items-center row">
      <Form.Label className="fw-bold lh-sm form-label col-form-label col-sm-5">
        Lower Level Term
      </Form.Label>
      <div className="col">{formControl}</div>
      <Form.Control
        hidden={true}
        name={lltCodeInputName}
        value={lltCodeFieldData.value || ""}
        readOnly={true}
      ></Form.Control>
      <Form.Control
        hidden={true}
        name={unableToCodeInputName}
        value={unableToCodeFieldData.value || "0"}
        readOnly={true}
      ></Form.Control>
    </div>
  );
};

export default LltInput;
