import React from "react";
import { useContext } from "react";
import { FormContext, FieldDataContext } from "../../../contexts";
import { Form, Table } from "react-bootstrap";
import { anyChildIsRendered } from "../../../services/dependencies/dependency_helper";
import ClearSelection from "../input_types/ClearSelection";

const RadioTable = ({ displayItem }) => {
  // Get the required data from our context
  const { readonly, fields } = useContext(FormContext);
  const { formValues, updateFormValue } = useContext(FieldDataContext);

  if (!anyChildIsRendered(displayItem.rows, formValues, fields, readonly)) {
    return null;
  }

  const tableHeader = (
    <>
      <th></th>
      {displayItem.options.map((option, index) => {
        return (
          <th className="text-center" key={index}>
            {option}
          </th>
        );
      })}
      {readonly ? null : <th></th>}
    </>
  );

  const rows = displayItem.rows.map((row, index) => {
    // Throw an error if we can't find a value to use for this field
    if (!(row.fieldIdentifier in formValues)) {
      console.error(`Cannot find value for field ${row.fieldIdentifier}`);
      return null;
    }

    const fieldID = `field_${row.fieldIdentifier}`;

    // Get a reference to our field data
    const fieldData = formValues[row.fieldIdentifier];

    if (!fieldData.dependency_met) {
      return null;
    }

    // Handle the fields onChange event
    const handleOnChange = (e) => {
      if (readonly) {
        e.preventDefault();
      } else {
        updateFormValue(row.fieldIdentifier, e.target.value || null);
      }
    };

    const handleClearSelection = () => {
      updateFormValue(row.fieldIdentifier, "");
    };

    const errors = fieldData.errors;

    // Build our error messages
    const errorFeedback = (
      <Form.Control.Feedback type="invalid">
        {errors.join(", ")}
      </Form.Control.Feedback>
    );

    return (
      <tr key={index} id={fieldID}>
        <td className="me-0">
          {/* Hidden field to allow reset */}
          {readonly ? null : (
            <Form.Control
              name={`form[${row.fieldIdentifier}]`}
              defaultValue=""
              hidden
            ></Form.Control>
          )}
          <div className={errors.length > 0 ? "is-invalid" : null}>
            {row.label}
          </div>
          {errorFeedback}
        </td>
        {displayItem.options.map((option, optionIndex) => {
          return (
            <td key={`${index}-${optionIndex}`} className="text-center">
              <Form.Check
                type="radio"
                aria-label={option}
                id={`${row.fieldIdentifier}-${optionIndex}`}
                name={`form[${row.fieldIdentifier}]`}
                value={option}
                checked={fieldData.value == option}
                onChange={(e) => {
                  handleOnChange(e);
                }}
                className={readonly ? "noClick" : " "}
                isInvalid={errors.length > 0}
              />
            </td>
          );
        })}
        {readonly ? null : (
          <td>
            <ClearSelection
              handler={handleClearSelection}
              fieldData={fieldData}
            ></ClearSelection>
          </td>
        )}
      </tr>
    );
  });

  return (
    <Table bordered responsive hover className="mt-3">
      <thead>
        <tr className="table-light">{tableHeader}</tr>
      </thead>
      <tbody>{rows}</tbody>
    </Table>
  );
};

export default RadioTable;
