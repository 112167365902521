import React from "react";

import { buildDisplayItems } from "../../../services/display_item_helper";

const TableCell = ({ displayItem }) => {
  const cellContent = buildDisplayItems(displayItem.children);
  return (
    <td>
      <div className="row m-0 align-items-center">{cellContent}</div>
    </td>
  );
};

export default TableCell;
