import React from "react";

import { Form, Button, Table, ButtonGroup } from "react-bootstrap";

import { buildDisplayItems } from "../../../services/display_item_helper";
import { InputContext } from "../../../contexts";

import Card from "react-bootstrap/Card";

const VerticalSubform = ({
  subform,
  buildDeleteButton,
  buildNewButton,
  buildReorderButtons,
  displayItem,
  readonly,
  buildHiddenFields,
  buildRowContext,
}) => {
  let row_num = 0;

  const rows = subform.subformData.map((row, index) => {
    const rowContent = (
      <Card
        className={
          row.marked_for_delete
            ? "rounded border border-2 my-2 border-danger"
            : "rounded border border-1 my-2"
        }
        id={`${subform.pluralizedIdentifier}_${index}`}
      >
        <Card.Header className="d-flex">
          <Card.Title className="fs-5 px-1 fw-bold">
            Entry {index + 1}
          </Card.Title>
          <div className="ms-auto">
            {readonly ? null : (
              <>
                {buildHiddenFields(row, index, row_num++)}

                {buildDeleteButton(row, index)}

                {buildReorderButtons(index)}
              </>
            )}
          </div>
        </Card.Header>
        <Card.Body>
          {buildDisplayItems(displayItem.children)}
          <div className="text-danger text-center">
            {row.base_errors.map((error, index) => {
              return <div key={index}>{error}</div>;
            })}
          </div>
        </Card.Body>
      </Card>
    );

    return buildRowContext(index, row, rowContent);
  });

  return (
    <InputContext.Provider
      value={{
        inputContext: null,
      }}
    >
      <fieldset
        className="prospect-fieldset rounded border border-1 pt-3 pb-2 mt-4"
        id={displayItem.subformIdentifier}
      >
        <legend className="fs-5 px-1 fw-bold">{displayItem.subformName}</legend>
        {rows.length == 0 ? (
          <div className="text-center">This subform contains no data</div>
        ) : (
          rows
        )}
        <div className="d-flex justify-content-end">{buildNewButton()}</div>
      </fieldset>
    </InputContext.Provider>
  );
};

export default VerticalSubform;
