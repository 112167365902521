import React from "react";
import { Form } from "react-bootstrap";

const PtInput = ({
  responseData,
  ptTermFieldData,
  lltTermFieldData,
  ptCodeFieldData,
  buildInputName,
}) => {
  const ptTermInputName = buildInputName("pt_term");
  const ptCodeInputName = buildInputName("pt_code");

  const hiddenFields = (
    <>
      <Form.Control
        hidden={true}
        name={ptCodeInputName}
        value={ptCodeFieldData.value || ""}
        readOnly={true}
      ></Form.Control>
      <Form.Control
        hidden={true}
        name={ptTermInputName}
        value={ptTermFieldData.value || ""}
        readOnly={true}
      ></Form.Control>
    </>
  );

  // Don't show this unless we have search results or an llt has been selected
  if (!responseData && !ptTermFieldData.value) {
    return hiddenFields;
  }

  const foundLlt =
    responseData &&
    responseData.find((llt) => {
      return llt.llt_name == lltTermFieldData.value;
    });

  // If we cannot find our LLT and we don't have a pt value, mark as blank
  if (!foundLlt && !ptTermFieldData.value) {
    return hiddenFields;
  }

  return (
    <div className="mb-3 align-items-center row">
      <Form.Label className="fw-bold lh-sm form-label col-form-label col-sm-5">
        Preferred Term
      </Form.Label>
      <div className="col">
        <Form.Control
          readOnly={true}
          plaintext={true}
          type="text"
          value={ptTermFieldData.value || ""}
          name={ptTermInputName}
          className="px-2 py-1"
        ></Form.Control>
      </div>
      <Form.Control
        hidden={true}
        name={ptCodeInputName}
        value={ptCodeFieldData.value || ""}
        readOnly={true}
      ></Form.Control>
    </div>
  );
};

export default PtInput;
