import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["screening", "site"];
  static values = {
    prepopulation: Array,
  };

  connect() {}

  siteUpdated() {
    this.clear_errors();
    this.prepopulateScreening();
  }

  prepopulateScreening() {
    this.screeningTarget.value = this.getPrepopulationText(
      this.siteTarget.value
    );
  }

  getPrepopulationText(site_id) {
    if (site_id == "") {
      return "";
    }
    return this.prepopulationValue.find((site) => site.site_id == site_id)
      .prepopulation;
  }

  clear_errors() {
    this.screeningTarget.classList.remove("is-invalid");
  }
}
