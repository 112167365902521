import React from "react";
import Button from "react-bootstrap/Button";

function ButtonLink({ className, button_text, link }) {
  return (
    <Button className={className} variant="default" href={link}>
      {button_text}
    </Button>
  );
}

export default ButtonLink;
