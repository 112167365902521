// Automatically generated at 2024-05-14 09:14
// Do not change this file - please update app/services/dependencies/dependency_checker.rb and re-generate

// This code is coverted to js via the ruby to js gem
// Things that you can't have:
// methods ending in a questionmark
// Use explicit return statements, as implicit returns are not always coverted to js correctly
// Regex statements are converted but the way they they are checked is different between js and ruby
export const Dependencies = {
  DependencyChecker: class {
    constructor(dependencyJson) {
      this._dependency = JSON.parse(dependencyJson);
    }

    dependencyMet(relevantDataJson) {
      let relevantData = JSON.parse(relevantDataJson);
      return this.evaluateDependency(this._dependency, relevantData);
    }

    // There are no private methods in JS, this indicates where the private methods would be
    // private
    evaluateDependency(dependency, relevantData) {
      let leftType,
        rightType,
        conditionType,
        left,
        right,
        anyPartialDates,
        anyFlags,
        anyNumeric,
        anyNil;

      if (
        [
          "==",
          "!=",
          "<",
          "<=",
          ">",
          ">=",
          "IN",
          "CONTAINS",
          "EXISTS",
          "BLANK",
        ].includes(dependency.conditionType)
      ) {
        leftType = dependency.left.valueType;
        rightType = dependency.right.valueType;
        conditionType = dependency.conditionType;

        if (this.invalidCondition(leftType, rightType, conditionType)) {
          // puts "Invalid comparison: Comparison of a #{left_type} with a #{right_type} using #{condition_type} comparator."
          return false;
        } else {
          left = this.findValue(dependency.left, relevantData);
          right = this.findValue(dependency.right, relevantData);
          anyPartialDates =
            dependency.left.valueType === "partialDate" ||
            dependency.right.valueType === "partialDate";
          anyFlags =
            dependency.left.valueType === "flag" ||
            dependency.right.valueType === "flag";
          anyNumeric =
            dependency.left.valueType === "numeric" ||
            dependency.right.valueType === "numeric";

          if (anyPartialDates) {
            return this.comparePartials(conditionType, left, right);
          } else if (anyFlags) {
            return this.compareFlags(conditionType, left, right);
          } else {
            anyNil = left === null || right === null;

            if (anyNumeric && conditionType !== "BLANK") {
              if (left !== null) left = parseFloat(left);
              if (right !== null) right = parseFloat(right);
            }

            switch (conditionType) {
              case "==":
              case "EXISTS":
                return left === right;

              case "!=":
                return left !== right;

              case "<":
                if (anyNil) return false;
                return left < right;

              case "<=":
                if (anyNil) return false;
                return left <= right;

              case ">":
                if (anyNil) return false;
                return left > right;

              case ">=":
                if (anyNil) return false;
                return left >= right;

              case "BLANK":
                let fieldBlank = left === null || left === "";

                // When looking at a BLANK condition, the left side will be the field, the right side will be either a TRUE or FALSE literal
                return fieldBlank === right;
            }
          }
        }
      } else if (dependency.conditionType === "AND") {
        return dependency.conditions.every((condition) =>
          this.evaluateDependency(condition, relevantData)
        );
      } else if (dependency.conditionType === "OR") {
        return dependency.conditions.some((condition) =>
          this.evaluateDependency(condition, relevantData)
        );
      } else {
        return true;
      }
    }

    // Ensure that the date is in the yyyy-mm-dd format before calling this method
    // We currently do not support modifiers on field dependencies meaning that the following code is not expected to work when converted to javascript
    // The above is theoretically possible but is not required currently by DM
    // If it is decided that field dependencies should support date modifiers, a method for modifying dates consistently between js and ruby will have to be found
    modifyDate(dateString, modifier) {
      let operator;

      // puts "Unrecognised modifier format '#{modifier}'"
      if (modifier.includes("+ ")) {
        operator = "+";
      } else if (modifier.includes("- ")) {
        operator = "-";
      }

      let yearMatch = /\d+[y]/.match(modifier);
      let monthMatch = /\d+[m]/.match(modifier);
      let weekMatch = /\d+[w]/.match(modifier);
      let dayMatch = /\d+[d]/.match(modifier);
      let modifierArray = [];

      if (yearMatch !== null) {
        modifierArray.push(parseInt(delete yearMatch[0].y).send("year"));
      }

      if (monthMatch !== null) {
        modifierArray.push(parseInt(delete monthMatch[0].m).send("month"));
      }

      if (weekMatch !== null) {
        modifierArray.push(parseInt(delete weekMatch[0].w).send("week"));
      }

      if (dayMatch !== null) {
        modifierArray.push(parseInt(delete dayMatch[0].d).send("day"));
      }

      let parsedModifier = modifierArray.reduce((a, b) => a + b, 0);
      let parsedDate = Date.parse(dateString);

      if (operator === "+") {
        return parsedDate + parsedModifier;
      } else if (operator === "-") {
        return parsedDate - parsedModifier;
      }
    }

    modifyNumeric(numericValue, modifier) {
      if (modifier.includes("+ ")) {
        return (
          parseFloat(numericValue) +
          parseFloat(modifier.slice(2, modifier.length))
        );
      } else if (modifier.includes("- ")) {
        return (
          parseFloat(numericValue) -
          parseFloat(modifier.slice(2, modifier.length))
        );
      }
    }

    // Pass in date either as dd/mm/yyyy or yyyy-mm-dd and convert to yyyy-mm-dd
    convertDate(date) {
      let splitValue;

      // If the value is a date and contains a "/", reorganise it into yyyy-mm-dd format before parsing
      if (date.includes("/")) {
        splitValue = date.split("/").reverse();
      } else {
        splitValue = date.split("-");
      }

      // Make sure all date parts have leading 0s as this was causing inconsistencies in how ruby/js parses the date string
      splitValue = splitValue.map((value) =>
        value.length === 1 ? `0${value}` : value
      );
      date = splitValue.join("-");
      return date;
    }

    convertDuration(duration, sourceJson) {
      // If the duration does not include a ":", assume it's already been converted to seconds
      if (!duration.toString().includes(":")) return duration;
      let splitDuration = duration.split(":");

      if (sourceJson.durationFormat === "h:mm") {
        return splitDuration[0] * 60 * 60 + splitDuration[1] * 60;
      } else {
        return splitDuration[0] * 60 + splitDuration[1];
      }
    }

    comparePartials(comparator, leftValue, rightValue) {
      let leftUpper = Array.isArray(leftValue) ? leftValue[1] : leftValue;
      let leftLower = Array.isArray(leftValue) ? leftValue[0] : leftValue;
      let rightUpper = Array.isArray(rightValue) ? rightValue[1] : rightValue;
      let rightLower = Array.isArray(rightValue) ? rightValue[0] : rightValue;

      switch (comparator) {
        case "==":
          return leftLower === rightLower && leftUpper === rightUpper;

        case "!=":
          return !(leftLower === rightLower && leftUpper === rightUpper);

        case "<":
          return leftUpper < rightLower;

        case "<=":
          return leftUpper <= rightLower;

        case ">":
          return leftLower > rightUpper;

        case ">=":
          return leftLower >= rightUpper;

        default:
          return true;
      }
    }

    compareFlags(comparator, leftValue, rightValue) {
      if (!["==", "!=", "IN", "CONTAINS"].includes(comparator)) return false;

      // When edith exports the dependency it puts the flag field on the left, and the literal on the right
      // Therefore we can treat IN and CONTAINS as being identical here
      if (["IN", "CONTAINS"].includes(comparator)) {
        if (!Array.isArray(leftValue)) return false;
        return leftValue.includes(rightValue);
      }

      // Sort and Stringify the arrays, then compare
      let sortedStringifiedLeft = leftValue.sort().join(",");
      let sortedStringifiedRight = rightValue.sort().join(",");

      if (comparator === "==") {
        return sortedStringifiedLeft === sortedStringifiedRight;
      } else {
        return sortedStringifiedLeft !== sortedStringifiedRight;
      }
    }

    findValue(source, relevantData) {
      let dateString, relevantDataKey, dataKeyParts;

      switch (source.type) {
        case "literal":
          if (source.valueType === "date") {
            if (source.value === "TODAY") {
              // If Date() is defined we are in JavaScript, otherwise ruby
              if (typeof Date() !== "undefined") {
                let todaysDate = new Date();
                let year = todaysDate.getFullYear().toString();
                let month = (todaysDate.getMonth() + 1).toString();
                let day = todaysDate.getDate().toString();
                if (month.length === 1) month = `0${month}`;
                if (day.length === 1) day = `0${day}`;
                dateString = `${year}-${month}-${day}`;
              } else {
                // The javascript Date() object, includes the current time, we need to reset this to 00:00
                dateString = Date.today().toString();
              }

              if (source.modifier) {
                return this.modifyDate(dateString, source.modifier);
              } else {
                return Date.parse(dateString);
              }
            }

            return Date.parse(this.convertDate(source.value));
          } else if (source.valueType === "boolean") {
            return source.value === "TRUE" ? true : false;
          } else {
            return source.value;
          }

          break;

        case "localField":
        case "formField":
        case "eventFormField":
        case "parentField":
        case "systemVariable":
        case "siteMeta":
          if (relevantData === "{}") return null;

          // Build the appropriate key based on the fields location
          switch (source.type) {
            case "localField":
              relevantDataKey = source.fieldIdentifier;
              break;

            case "formField":
              relevantDataKey = `${source.formIdentifier}.${source.fieldIdentifier}`;
              break;

            case "eventFormField":
              relevantDataKey = `${source.eventIdentifier}.${source.formIdentifier}.${source.fieldIdentifier}`;
              break;

            case "parentField":
              relevantDataKey = `parent.${source.fieldIdentifier}`;
              break;

            case "systemVariable":
              if (
                [
                  "event_identifier",
                  "event_name",
                  "site.identifier",
                  "site.name",
                  "event_date",
                ].includes(source.variableType)
              ) {
                relevantDataKey = source.variableType;
              } else if (source.variableType === "count") {
                relevantDataKey = `${source.subformIdentifier}.count`;
              } else {
                // created_at or updated_at
                dataKeyParts = [];
                if (source.eventIdentifier)
                  dataKeyParts.push(source.eventIdentifier);
                if (source.formIdentifier)
                  dataKeyParts.push(source.formIdentifier);
                dataKeyParts.push(source.fieldIdentifier);
                relevantDataKey = dataKeyParts.join(".");
              }

              break;

            case "siteMeta":
              relevantDataKey = source.metaFieldId;
          }

          let value = relevantData[relevantDataKey];
          if (!value && value !== 0 && value !== false) return null;

          if (source.valueType === "date") {
            if (["", null].includes(value)) return null;

            if (source.modifier) {
              return this.modifyDate(this.convertDate(value), source.modifier);
            } else {
              return Date.parse(this.convertDate(value));
            }
          } else if (source.valueType === "partialDate") {
            // If this is a partial date, we expect it to have upper and lower bounds. Parse each of these
            return value.map((partialDate) =>
              Date.parse(this.convertDate(partialDate))
            );
          } else if (source.valueType === "duration") {
            return this.convertDuration(value, source);
          } else if (source.valueType === "numeric" && source.modifier) {
            return this.modifyNumeric(value, source.modifier);
          } else {
            return value;
          }

          break;

        case "form":
          dataKeyParts = [];
          if (source.eventIdentifier) dataKeyParts.push(source.eventIdentifier);
          dataKeyParts.push(source.formIdentifier);
          dataKeyParts.push("exists");
          relevantDataKey = dataKeyParts.join(".");
          return relevantData[relevantDataKey];

        default:
          return null;
      }
    }

    invalidCondition(leftType, rightType, conditionType) {
      // BLANK condition allowed between all types
      if (conditionType === "BLANK") return false;

      if (this.sameFieldType(leftType, rightType)) {
        return this.invalidComparisionForType(
          leftType,
          rightType,
          conditionType
        );
      } else if (this.enumStringComparison(leftType, rightType)) {
        return (
          this.invalidComparisionForType(leftType, rightType, conditionType) ||
          this.typesMismatched(leftType, rightType, conditionType)
        );
      } else {
        return this.typesMismatched(leftType, rightType, conditionType);
      }
    }

    invalidComparisionForType(leftType, rightType, conditionType) {
      if (
        ((this.sameFieldType(leftType, rightType) &&
          (leftType === "string" ||
            leftType === "boolean" ||
            leftType === "enum" ||
            leftType === "flag")) ||
          this.enumStringComparison(leftType, rightType)) &&
        (conditionType === "<" ||
          conditionType === ">" ||
          conditionType === "<=" ||
          conditionType === ">=")
      ) {
        return true;
      } else {
        return false;
      }
    }

    sameFieldType(leftType, rightType) {
      return leftType === rightType;
    }

    typesMismatched(leftType, rightType, conditionType) {
      if (this.enumStringComparison(leftType, rightType)) {
        return false;
      } else if (this.datePartialDateComparison(leftType, rightType)) {
        return false;
      } else if (
        this.flagStringComparison(leftType, rightType, conditionType)
      ) {
        return false;
      } else {
        return true;
      }
    }

    enumStringComparison(leftType, rightType) {
      return (
        (leftType === "enum" && rightType === "string") ||
        (leftType === "string" && rightType === "enum")
      );
    }

    datePartialDateComparison(leftType, rightType) {
      return (
        (leftType === "date" && rightType === "partialDate") ||
        (leftType === "partialDate" && rightType === "date")
      );
    }

    flagStringComparison(leftType, rightType, conditionType) {
      return (
        leftType === "flag" &&
        rightType === "string" &&
        (conditionType === "CONTAINS" || conditionType === "IN")
      );
    }

    durationComparison(leftType, rightType) {
      return this.sameFieldType(leftType, rightType) && leftType === "duration";
    }
  },
};
